import type { QueryBrands, DisplayBrands } from "~/@types/brands"

export default (brand?: QueryBrands): DisplayBrands => {
  if (!brand) {
    return {} as DisplayBrands
  }

  return {
    ...brand,
    logo: brand?.logo?.asset?._ref,
    name: brand?.name,
    permalink: `/brands/${brand?.slug?.current}`,
  }
}
